@font-face {
  font-family: 'Brandon Grotesque';
  src: url(./Fonts/brandon-grotesque-black-58a8a3e824392.otf);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon Grotesque Light';
  src: url(./Fonts/brandon-grotesque-light-58a8a4b38001d.otf);
  font-weight: lighter;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

body, html {
  margin: 0;
  padding: 0;
  overflow: hidden; /* prevent scrollbars */
  width: 100%; /* full width */
  height: 100%; /* full height */
  background-color: #1f2232;
}

.app-container {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  position: absolute; /* for absolute positioning of child elements */
}

#root {
  width: 100%;
  height: 100%;
}

.center-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  text-align: center;
}

h1{
  color: #985f99;
  font-weight: 900;
  font-family: 'Brandon Grotesque', 'Franklin Gothic Medium'
  
}

a:link, a:visited{
  color: #985f99;
}

h1, a:link, a:visited{
  margin: 0px;
}

.image {
  scale: 100%;
  margin: 0%;
  padding: 0%;
  position: relative;
  border-radius: 3%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

