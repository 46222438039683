.headshot {
  scale: 100%;
  margin: 0%;
  padding: 0%;
  position: relative;
  border-radius: 3%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

h1 {
  font-size: calc(30px + (48 - 24) * ((100vw - 300px) / (1600 - 300)));
  margin-bottom: calc(16px + (32 - 16) * ((100vw - 300px) / (1600 - 300)));
}

/* Optional: If screen width gets too small, prevent the font from becoming tiny */
@media (max-width: 300px) {
  h1 {
    font-size: 24px;
    margin-bottom: 16px;
  }
}

/* Optional: If screen width gets too large, prevent the font from becoming overly large */
@media (min-width: 1600px) {
  h1 {
    font-size: 48px;
    margin-bottom: 32px;
  }
}