@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}

.circle0, .circle1, .circle2, .circle3, .circle4, .circle5, .circle6, .circle7 {
  opacity: 0;
  animation-name: fadeIn;
  animation-timing-function: ease-in-out; /* ensure smooth fading */
  animation-duration: 1s; /* 1s for fade in */
  animation-fill-mode: forwards; /* to ensure the end opacity remains at 1 */
  animation-delay: 6500ms; 
  z-index: 1;
  background-color: #44ffd1;
}